<script>
export default {
	name: "ElementIcon",
	props: {
        element: {
            type: String
        },
        title: {
            type: String
        },
        small: Boolean,
        smallCardPage: Boolean
	},
};
</script>

<template>
	<div
        class="element__icon"
        :class="[`element__icon--${element}`, {'element__icon--small' : small}, {'element__icon--small-card-page' : smallCardPage}]"
        :title="`${title} element`"
    >
	</div>
</template>


<style lang="scss" scoped>
.element__icon {
    display: inline-block;
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    vertical-align: top;

	&--arcane {
        background-image: url(/images/icons/elements/arcane.png);
    }
    &--astra {
        background-image: url(/images/icons/elements/astra.png);
    }
    &--crux {
        background-image: url(/images/icons/elements/crux.png);
    }
    &--fire {
        background-image: url(/images/icons/elements/fire.png);
    }
    &--luxem {
        background-image: url(/images/icons/elements/luxem.png);
    }
    &--neos {
        background-image: url(/images/icons/elements/neos.png);
    }
    &--norm {
        background-image: url(/images/icons/elements/norm.png);
    }
    &--tera {
        background-image: url(/images/icons/elements/tera.png);
    }
    &--umbra {
        background-image: url(/images/icons/elements/umbra.png);
    }
    &--water {
        background-image: url(/images/icons/elements/water.png);
    }
    &--wind {
        background-image: url(/images/icons/elements/wind.png);
    }
    &--small {
        height: 30px;
        width: 30px;
        background-size: 30px;
    }
    &--small-card-page {
        height: 32px;
        width: 32px;
        background-size: 32px;
        margin-bottom: 3px;
        vertical-align: middle;
    }
}
</style>
