export default {
    debug: true,
    protocol: 'http',
    url: {
        api: 'http://13.56.162.182:8090',
        image: 'http://9me5dwv8y8.dreamhosters.com/images'
    },
    gtag: {
        id: 'G-P06DM95KYD',
        appName: 'GA Index'
    },
    disqus: {
        shortname: 'gatcg'
    },

    enable: {
        disqus: false,
        gtag: false
    }
}