<script>
export default {
	name: "TypeIcon",
	props: {
        types: {
            type: Array
        },
        title: {
            type: String
        },
        small: Boolean,
	},
	computed: {
        typeClass () {
            let types = this.types.slice()

            // always move REGALIA to the last item
            if (types.indexOf('REGALIA') > -1 ) {
                types.push(types.splice(types.indexOf('REGALIA'), 1).pop());
            }

            // always move ALLY to the last item
            if (types.indexOf('ALLY') > -1 ) {
                types.push(types.splice(types.indexOf('ALLY'), 1).pop());
            }

            const filteredTypes = types.filter(function (type) {
                const ignoreList = ['UNIQUE', 'TOKEN']

                return ignoreList.indexOf(type) < 0
            });
            // return types.join('-').toLowerCase()
            return filteredTypes.join('-').toLowerCase()
        }
	},
};
</script>

<template>
	<div
        class="type__icon"
        :class="[`type__icon--${typeClass}`]"
        :title="`${title} type`"
    >
	</div>
</template>


<style lang="scss" scoped>
.type__icon {
    display: inline-block;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    vertical-align: top;
    margin-right: 5px;

	&--ally {
        background-image: url(/images/icons/types/ally.png);
    }
    &--ally-regalia {
        background-image: url(/images/icons/types/ally-regalia.png);
    }
    &--action {
        background-image: url(/images/icons/types/action.png);
    }
    &--attack {
        background-image: url(/images/icons/types/attack.png);
    }
    &--champion {
        background-image: url(/images/icons/types/champion.png);
    }
    &--domain {
        background-image: url(/images/icons/types/domain.png);
    }
    &--item {
        background-image: url(/images/icons/types/item.png);
    }
    &--item-regalia {
        background-image: url(/images/icons/types/item-regalia.png);
    }
    &--weapon {
        background-image: url(/images/icons/types/weapon.png);
    }
    &--weapon-regalia {
        background-image: url(/images/icons/types/weapon-regalia.png);
    }
    &--phantasia {
        background-image: url(/images/icons/types/phantasia.png);
    }
    
    &--phantasia-ally {
        background-image: url(/images/icons/types/phantasia.png), url(/images/icons/types/ally.png);
        background-position: left, right;
        width: 40px;
    }

    &--phantasia-regalia-ally {
        background-image: url(/images/icons/types/phantasia-regalia.png), url(/images/icons/types/ally-regalia.png);
        background-position: left, right;
        width: 40px;
    }
}
</style>
